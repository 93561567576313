import Flv from "mpegts.js";
import { liveBaseUrl, streamServerBaseUrl } from "./config";
import Axios from "axios";
import Util from "@/common/js/util.js";
class Player {
    /**
     * @param {CombinedVueInstance} component
     * @param {string} uuid
     * @param {String} url video url
     * @param {flvConfig} flvConfig
     */
    constructor(component, uuid, url, flvConfig, playerConfig) {
        this.vm = component;
        // 播放状态
        this.isPlay = false;
        // http-live是否处于连接状态
        this.isConnect = false;
        // 全屏状态
        this.isFull = false;
        this.videoEl = document.getElementById(uuid);
        this.uuid = uuid;
        this.url = url;
        this.flvConfig = {
            enableWorker: true,
            enableStashBuffer: true,
            autoCleanupSourceBuffer: true, //重用301/302重定向url，用于随后的请求，如查找、重新连接等
            reuseRedirectedURL: true,
            ...flvConfig
        };
        // 音频默认为0
        this.videoEl.volume = playerConfig.volume;
        this.isHistory = false;
        // 当前播放倍数
        this.currentSpeed = 0;
        // 默认子码流  从外面传入：playerConfig.stream
        this.codeType = 1;
        // 默认流畅模式
        this.modeType = playerConfig.mode;
        this.setPlayMode(this.modeType);
        // 0音视频 1音频 2视频 3视频或音视频
        this.mediaType = "0";
        // 下载网速
        this.network = 0;
        this.historyConfig = {
            // 历史：存储器类型 0主存储器或灾备存储器 1主存储器 2灾备存储器
            storageType: "0",
            // 历史：回放方式 0正常回放 1快进回放 2关键帧快退回放 3关键帧播放 4单帧上传
            playType: "0",
            // 快进或快退倍数 0:无效 1:1倍 2:2倍 3:4倍 4:8倍 5:16倍
            playSpeed: "0",
            // 以秒记
            timeout: 10
        };
        // 历史可播放资源列表
        this.playbackList = [];
        this.strategy = playerConfig.historyStrategy;

        // 录屏相关
        this.isRecord = false;
        this.chunks = [];
        this.abortController = null;
        this.reader = null;
    }

    static isSupported() {
        return Flv.isSupported();
    }

    setHost(ip, port) {
        this.hostIP = ip;
        this.hostPort = port;
    }

    setIsHistory(isHistory) {
        this.isHistory = isHistory;
    }
    setCurrentTime(value) {
        this.flvPlayer && (this.flvPlayer.currentTime = value);
    }

    setTimeRange(start, end) {
        if (start) {
            this.beginTime = start;
        }
        if (end) {
            this.endTime = end;
        }
    }

    // 请求参数
    setOption(option) {
        this.option = option;
    }
    setChannelId(id) {
        this.option.channelId = id;
        this.url = this.option.clientId + "-" + this.option.channelId;
        if (this.isPlay) {
            this.relink();
        }
    }
    setDeviceId(id) {
        this.option.clientId = id;
        this.url = this.option.clientId + "-" + this.option.channelId;
        if (this.isPlay) {
            this.relink();
        }
    }
    setPlayMode(mode) {
        if (mode) {
            // 实时
            Object.assign(this.flvConfig, {
                // 384kb
                enableStashBuffer: false,
                liveBufferLatencyChasing: true,
                stashInitialSize: 384 * 1024
            });
        } else {
            Object.assign(this.flvConfig, {
                // 1m
                enableStashBuffer: true,
                stashInitialSize: 1 * 1024 * 1024,
                liveBufferLatencyChasing: false
            });
        }
        this.modeType = mode;
    }

    // 设置播放速度
    setPlaybackRate(rate) {
        this.videoEl && (this.videoEl.playbackRate = rate);
    }

    initFlvPlayer() {
        if (this.url) {
            this.flvPlayer = new Flv.createPlayer(
                {
                    type: "flv",
                    url: liveBaseUrl + this.url,
                    cors: true,
                    isLive: true
                },
                this.flvConfig
            );
            this.flvPlayer.on(Flv.Events.STATISTICS_INFO, res => {
                this.network = Math.floor(res.speed);
                if (this.flvPlayer.buffered && this.flvPlayer.buffered.length > 0) {
                    if (this.isPlay && this.isHistory) {
                        const length = this.flvPlayer.buffered && this.flvPlayer.buffered.length;
                        const bufferUnplayTime = this.flvPlayer.buffered.end(length - 1) - this.flvPlayer.currentTime;
                        this.vm.bufferTime = bufferUnplayTime.toFixed(2);
                        // 同步currentTime
                        this.vm.currentTime = this.flvPlayer.currentTime * 1000;
                    } else if (!this.isPlay) {
                        if (this.modeType) {
                            // 实时模式
                            this.flvPlayer && this.flvPlayer.play();
                            this.vm.loading = false;
                        } else {
                            // 流畅模式默认缓存5秒
                            setTimeout(() => {
                                this.flvPlayer && this.flvPlayer.play();
                                this.vm.loading = false;
                            }, 5000);
                        }
                        this.isPlay = true;
                    }
                }
            });

            this.flvPlayer.on(Flv.Events.ERROR, (errorType, errorDetail, errorInfo) => {
                console.error("errorType:", errorType);
                console.error("errorDetail:", errorDetail);
                console.error("errorInfo:", errorInfo);
            });
        }
    }
    destroyFlvPlayer() {
        if (this.flvPlayer) {
            //   this.flvPlayer.off("statistics_info", function() {});
            //   this.flvPlayer.off(Flv.Events.ERROR, function() {});
            //   this.flvPlayer.off("METADATA_ARRIVED", function() {});
            this.flvPlayer.destroy();
            this.flvPlayer = null;
            this.videoEl.currentTime = 0;
            this.vm.currentTime = 0;
        }
    }

    /**
     * 断开重连
     */
    relink() {
        this.stopVideo();
        setTimeout(() => {
            this.playVideo();
        }, 500);
    }

    playVideo() {
        if (!this.isConnect && this.option.clientId && this.option.channelId) {
            this.initFlvPlayer();
            this.flvPlayer.attachMediaElement(this.videoEl);
            this.flvPlayer.load(); //加载
            this.vm.loading = true;
            let queryParams = null;
            this.isConnect = true;
            // 打开视频流
            let url = "/realtimeMediaUploadReq";
            if (this.isHistory) {
                url = "/historyMediaUploadReq";
                queryParams = {
                    ...this.option,
                    ...this.historyConfig,
                    beginTime: this.beginTime,
                    endTime: this.endTime
                };
            } else {
                queryParams = this.option;
            }
            // 延迟600ms调用打开指令，防止未连接就断开
            setTimeout(() => {
                Axios.get(url, {
                    baseURL: streamServerBaseUrl,
                    params: {
                        hostIP: this.hostIP,
                        hostPort: this.hostPort,
                        codeType: this.codeType,
                        mediaType: this.mediaType,
                        ...queryParams
                    },
                    headers: {
                        token: Util.getStor("token")
                    },
                    timeout: 10 * 1000
                });
            }, 600);
        }
    }

    stopVideo() {
        this.isPlay = false;
        this.destroyFlvPlayer();
        this.isConnect = false;
        this.network = 0;
        this.vm.loading = false;
        if (this.isRecord) {
            this.closeRecord();
        }
    }

    pauseVideo() {
        this.flvPlayer.pause();
        this.isPlay = false;
        if (this.isRecord) {
            this.closeRecord();
        }
    }

    snapShot() {
        if (this.isPlay) {
            const canvasElm = document.createElement("canvas");
            const ctx = canvasElm.getContext("2d");
            canvasElm.width = this.videoEl.videoWidth;
            canvasElm.height = this.videoEl.videoHeight;
            ctx.drawImage(this.videoEl, 0, 0, canvasElm.width, canvasElm.height);
            const strDataURL = canvasElm.toDataURL("image/" + "png"); // canvas中video中取一帧图片并转成dataURL
            if (strDataURL) {
                let arr = strDataURL.split(","),
                    mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]),
                    n = bstr.length,
                    u8arr = new Uint8Array(n);
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                const blob = new Blob([u8arr], {
                    type: mime
                });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.style.display = "none";
                a.href = url;
                a.download = "视频截图" + "_" + new Date().getTime() + ".png";
                a.click();
                setTimeout(function() {
                    window.URL.revokeObjectURL(url);
                }, 500);
            }
        }
    }

    startRecord() {
        if (this.isPlay && !this.isRecord) {
            this.abortController = new AbortController();
            fetch(liveBaseUrl + this.url, {
                method: "GET",
                mode: "cors",
                cache: "default",
                referrerPolicy: "no-referrer-when-downgrade",
                signal: this.abortController.signal
            }).then(async response => {
                this.reader = response.body.getReader();
                const _this = this;
                this.isRecord = true;
                this.reader.read().then(function receivedFlvData(chunk) {
                    if (chunk.done) {
                        return;
                    }
                    _this.chunks.push(chunk.value);
                    return _this.reader.read().then(receivedFlvData);
                });
            });
        }
    }

    endRecord() {
        if (this.chunks.length) {
            let blob = new Blob(this.chunks);
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement("a");
            link.href = url;
            link.download = new Date().getTime() + ".flv";
            link.click();

            // 释放url对象,停止请求数据
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
                this.closeRecord();
            }, 100);
        }
    }

    closeRecord() {
        this.abortController && this.abortController.abort();
        this.abortController = null;
        this.reader && this.reader.cancel("关闭读取");
        this.reader = null;
        this.isRecord = false;
        this.chunks = [];
        this.vm && (this.vm.isRecord = false);
    }

    changeStream(stream) {
        this.codeType = stream;
        if (this.isPlay) {
            // 暂停视频
            this.relink();
        }
    }

    changeMode(mode) {
        this.setPlayMode(mode);
        if (this.isPlay) {
            this.relink();
        }
    }

    toggleScreen() {
        if (this.isFull) {
            this.outFullScreen();
        } else {
            this.inFullScreen();
        }
        this.isFull = !this.isFull;
    }

    outFullScreen() {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        }
    }

    inFullScreen() {
        const rootNode = this.vm.$refs[this.uuid + "_wrapper"];
        if (rootNode.requestFullscreen) {
            rootNode.requestFullscreen();
        } else if (rootNode.mozRequestFullScreen) {
            rootNode.mozRequestFullScreen();
        } else if (rootNode.webkitRequestFullscreen) {
            rootNode.webkitRequestFullscreen();
        } else if (rootNode.msRequestFullscreen) {
            rootNode.msRequestFullscreen();
        }
    }

    setVolume(number) {
        if (this.flvPlayer) {
            this.flvPlayer.volume = number / 100;
        }
    }

    // 快进
    changeFaster() {
        if (this.currentSpeed != 16 && this.isPlay) {
            Axios.get("/historyMediaUploadControl", {
                baseURL: streamServerBaseUrl,
                params: {
                    playControl: "3",
                    playSpeed: this.currentSpeed,
                    playStartTime: this.beginTime,
                    timeout: 10,
                    ...this.option
                },
                timeout: 10 * 1000
            }).then(() => {
                // 更改播放速度
                if (this.currentSpeed < 0) {
                    this.currentSpeed = 0;
                } else if (this.currentSpeed == 0) {
                    this.currentSpeed = 1;
                } else {
                    this.currentSpeed *= 2;
                }
            });
        }
    }

    // 快退
    changeSlower() {
        if (this.currentSpeed != -16 && this.isPlay) {
            Axios.get("/historyMediaUploadControl", {
                baseURL: streamServerBaseUrl,
                params: {
                    playControl: "4",
                    playSpeed: Math.abs(this.currentSpeed),
                    playStartTime: this.beginTime,
                    timeout: 10,
                    ...this.option
                },
                timeout: 10 * 1000
            }).then(() => {
                // 更改播放速度
                if (this.currentSpeed > 0) {
                    this.currentSpeed = 0;
                } else if (this.currentSpeed == 0) {
                    this.currentSpeed = -1;
                } else {
                    this.currentSpeed *= 2;
                }
            });
        }
    }

    /**
     *
     * @param {String} time 20210524093412
     */
    seekCurrent(start) {
        this.setTimeRange(start);
        this.relink();
    }

    destroy() {
        this.stopVideo();
        this.videoEl = null;
        this.component = null;

        // 关闭录屏
        this.closeRecord();
    }
}

export default Player;
